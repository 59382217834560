<template>
  <div>
    <div class="white-box p-3" v-for="taskGroup in tasks" :key="taskGroup.id">
      <h2>{{ taskGroup.name }}</h2>
      <div class="row task-timeline">
        <div class="col-5">
          <div class="row task-timeline-titles">
            <div class="col-4">Task</div>
            <div class="col-1">%</div>
            <div class="col-2">Assigned</div>
            <div class="col-2">Start</div>
            <div class="col-2">End</div>
            <div class="col-1">Days</div>
          </div>
          <div
            class="row task-timeline-item"
            v-for="task in taskGroup.workTasks"
            :key="task.id"
          >
            <div class="col-4">{{ task.name }}</div>
            <div class="col-1">10%</div>
            <div class="col-2">Username</div>
            <div class="col-2">{{ formatDate(task.started_at) }}</div>
            <div class="col-2">{{ formatDate(task.ended_at) }}</div>
            <div class="col-1">14</div>
          </div>
        </div>
        <div class="col-7 x-scroll">
          <div class="task-timeline-days task-timeline-head">
            <div
              v-for="day in getNextDays()"
              :class="{ sticky: day.month.length > 0 }"
              :key="day.i"
            >
              {{ day.month }}
            </div>
          </div>
          <div class="task-timeline-days task-timeline-head">
            <div v-for="day in getNextDays()" :key="day.i">
              {{ day.weekday }}
            </div>
          </div>
          <div class="task-timeline-days task-timeline-head mb-3">
            <div v-for="day in getNextDays()" :key="day.i">
              {{ day.day }}
            </div>
          </div>
          <div
            v-for="task in taskGroup.workTasks"
            :key="task.id"
            class="task-timeline-days"
          >
            <div
              v-for="day in getNextDays()"
              :key="day.i"
              :class="{
                'bg-green': isDateBetween(
                  day.date,
                  task.started_at,
                  task.ended_at
                ),
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import date from "../../../modules/date";
import http from "@/modules/http";

export default {
  name: "Tasks",
  data() {
    return {
      tasks: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      http.fetch("/work/buckets/" + this.id).then((data) => {
        this.tasks = data.tasks;
      });
    },
    formatDate(val) {
      return date.format(val, false, false);
    },
    getNextDays(limit = 60) {
      const days = [];
      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 5);
      let lastMonth = -1;
      for (let i = 0; i < limit; i++) {
        const dayObj = {};
        dayObj.i = i;
        if (lastMonth !== currentDate.getMonth()) {
          lastMonth = currentDate.getMonth();
          dayObj.month = months[currentDate.getMonth()];
        } else {
          dayObj.month = "";
        }
        dayObj.day = currentDate.getDate();
        dayObj.weekday = weekdays[currentDate.getDay()];

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");

        dayObj.date = `${year}-${month}-${day}`;
        days.push(dayObj);

        currentDate.setDate(currentDate.getDate() + 1); // Move to next day
      }

      return days;
    },
    isDateBetween(dateToCheck, startDate, endDate) {
      if (startDate === null || endDate === null) {
        return false;
      }
      const dateToCheckObj = new Date(dateToCheck);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      startDateObj.setHours(0);
      startDateObj.setMinutes(0);
      startDateObj.setSeconds(0);
      endDateObj.setHours(23);
      endDateObj.setMinutes(59);
      endDateObj.setSeconds(59);

      return dateToCheckObj > startDateObj && dateToCheckObj < endDateObj;
    },
  },
};
</script>
